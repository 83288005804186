const emitRequest = {
    printFormatCode: {
        type: "integer",
        description: "Indicate to emit API that label response must be in a especific format",
        values: {
            1: "Thermical printer.",
            2: "ZPL printer.",
            3: "XML Data",
            4: "PDF Base64"
        }
    },
    orderNumber: {
        type: "String",
        maxSize: 20,
        description: "It is the unique identicator that you can use as ID in you own system."
    },
    references: {
        type: "Array[String(20)]",
        maxSize: 20,
        description: "Identificator asociated to OS"
    },
    serviceType: {
        type: "String",
        maxSize: 2,
        description: "Bluex's services types.",
        values: {
            EX: "Express",
            MD: "Sameday",
            PY: "Priority"
        }
    },
    productType: {
        type: "String",
        maxSize: 3,
        description: "Bluex's product types.",
        values: {
            P: "Paquete.",
            SOB: "Sobre.",
            SYG: "Supermercado y Grandes Tiendas."
        },
    },
    productCategory: {
        type: "String",
        maxSize: 4,
        description: "Bluex's product types.",
        values: {
            PAQU: "Paquete.",
            SOBR: "Sobre.",
            SYGT: "Supermercado y Grandes Tiendas."
        },
    },
    currency: {
        type: "String",
        maxSize: 3,
        description: "Currency type."
    },
    shipmentCost: {
        type: "Float",
        description: "Cost of shipment."
    },
    extendedClaim: {
        type: "Boolean",
        description: "Indicate if client need to extends insurance coverage."
    },
    companyId: {
        type: "Integer",
        description: "Type of company.",
        value: 2000
    },
    userName: {
        type: "String",
        maxSize: 10,
        description: "Bluex's  integration user name (codigo_persona). Ask for it to Blue'x integration team."
    },
    comments: {
        type: "String",
        maxSize: 100,
        description: "Some important coment. It will be appear at label."
    },
    pickup: {
        location: {
            stateId: {
                type: "Integer",
                description: "Bluex's state identificator. You can get all Bluex's state at https://bx-tracking.bluex.cl/bx-geo/state/all."
            },
            districtId: {
                type: "String",
                maxSize: 3,
                description: "Bluex's district identificator. You can get all Bluex's state at https://bx-tracking.bluex.cl/bx-geo/state/all."
            },
            address: {
                type: "String",
                maxSize: 80,
                description: "Pickup full address."
            },
            name: {
                type: "String",
                maxSize: 50,
                description: "Pickup location name."
            }
        },
        contact: {
            fullname: {
                type: "String",
                maxSize: 50,
                description: "Contact full name."
            },
            phone: {
                type: "String",
                maxSize: 20,
                description: "Contact phone number."
            }
        }
    },
    dropoff: {
        contact: {
            fullname: {
                type: "String",
                maxSize: 50,
                description: "Contact full name."
            },
            phone: {
                type: "String",
                maxSize: 20,
                description: "Contact phone number."
            }
        },
        location: {
            stateId: {
                type: "Integer",
                description: "Bluex's state identificator. You can get all Bluex's state at https://bx-tracking.bluex.cl/bx-geo/state/all."
            },
            districtId: {
                type: "String",
                maxSize: 3,
                description: "Bluex's district identificator. You can get all Bluex's state at https://bx-tracking.bluex.cl/bx-geo/state/all."
            },
            address: {
                type: "String",
                maxSize: 80,
                description: "Drop off full address."
            },
            name: {
                type: "String",
                maxSize: 50,
                description: "Drop off location name."
            }
        }
    },
    packages: [
        {
            weightUnit: {
                type: "String",
                maxSize: 2,
                description: "Measure weight unit.",
                values: {
                    KG: "Kilogramos",
                    LB: "Libre"
                }
            },
            lengthUnit: {
                type: "String",
                maxSize: 2,
                description: "Measure length unit.",
                values: {
                    CM: "Centrímetros.",
                    IN: "Pugadas.",
                    M: "Metros.",
                    YD: "Yardas."
                }
            },
            weight: {
                type: "Float",
                description: "Weight of package.",
            },
            length: {
                type: "Float",
                description: "Length of package.",
            },
            width: {
                type: "Float",
                description: "Width of package.",
            },
            height: {
                type: "Float",
                description: "Height of package.",
            },
            quantity: {
                type: "Integer",
                description: "Quantity of package.",
            },
            // Optional
            extras: [
                {
                    name: {
                        type: "String",
                        description: "Extra key.",
                    },
                    value: {
                        type: "String",
                        description: "Extra value.",
                    }
                }
            ]
        }
    ],
    // Optional
    dangerousPackages: [
        {
            packageTypeCode: {
                type: "Integer",
                description: "Dangerous package type.",
                values: {
                    1: "COMBURENTES (OXIDANTES)",
                    2: "CORROSIVOS",
                    4: "GAS INFLAMABLE",
                    5: "GAS NO INFLAMABLE, NO TOXICO",
                    6: "GAS TOXICO",
                    7: "LIQUIDO INFLAMABLE",
                    9: "MISCELANEOS",
                    10: "OTROS",
                    11: "PEROXIDOS ORGANICOS",
                    12: "SOLIDO INFLAMABLE",
                    13: "SUSTANCIA INFECCIOSAS",
                    14: "SUSTANCIA INFLAMABLES",
                    15: "SUSTANCIA TOXICA",
                    16: "SUSTANCIAS PROPENSAS A COMBUSTION ESPONTANEA",
                    17: "SUSTANCIAS QUE, EN CONTACTO CON EL AGUA"
                }
            }
        }
    ],
    // Optional
    returnDocuments: [
        {
            documentTypeCode: {
                type: "String",
                description: "Document type code.",
                values: {
                    G: "GUIA",
                    F: "FACTURA",
                    N: "NOTA DE CREDITO",
                    C: "CONTRATO",
                    B: "BOLETA",
                    O: "ORDEN DE PEDIDO",
                    P: "PASAJES/TICKET",
                    X: "OTROS DOCUMENTOS",
                    NA: "NO APLICA"
                }
            },
            documentNumber: {
                type: "String",
                description: "Document number."
            }
        }
    ],
    // Optional
    collectionsOnDelivery: [
        {
            documentTypeCode: {
                type: "String",
                maxSize: 2,
                description: "Document type code.",
                values: {
                    G: "GUIA",
                    F: "FACTURA",
                    N: "NOTA DE CREDITO",
                    C: "CONTRATO",
                    B: "BOLETA",
                    O: "ORDEN DE PEDIDO",
                    P: "PASAJES/TICKET",
                    X: "OTROS DOCUMENTOS",
                    NA: "NO APLICA"
                }
            },
            documentNumber: {
                type: "String",
                description: "Document number."
            },
            paymentType: {
                type: "String",
                maxSize: 2,
                description: "Document type code.",
                values: {
                    SF: "CARTA DCTO.FUNC.",
                    CA: "CASH",
                    CD: "CHEQUE",
                    CF: "CHEQUE A FECHA",
                    CH: "CHEQUE AL DIA",
                    CC: "CREDIT CARD",
                    SD: "DEPOSITO",
                    SL: "DEPOSITO LANCOURIER",
                    SE: "EFECTIVO",
                    SC: "LETRAS DE CAMBIO",
                    SN: "NOTA DE CREDITO",
                    SO: "OTROS TIPOS",
                    SP: "PAGARE",
                    SX: "T. AMEX",
                    SB: "T. CABAL",
                    SR: "T. DINERS",
                    SG: "T. MAGNA",
                    SM: "T. MASTERCARD",
                    SS: "T. VISA",
                    SV: "VALE VISTA"
                }
            },
            ammount: {
                type: "Float",
                description: "Payment amount."
            },
            currency: {
                type: "String",
                maxSize: 3,
                description: "Currency type."
            },
            serialNumberDocument: {
                type: "String",
                maxSize: 50,
                description: "Document serial number."
            },
            bankCode: {
                type: "Integer",
                description: "Bank code.",
                values: {
                    1: "BANCO DE CHILE",
                    2: "BANCO CREDITO DEL PERU",
                    3: "BANCO INTERNACIONAL",
                    5: "BANCO LATINO",
                    7: "CITIBANK PERU",
                    8: "EXTERIOR DE LOS ANDES Y DE ESP.",
                    9: "BANCO INTERNACIONAL",
                    10: "BANCO REAL GRÁFICA",
                    11: "BANCO CONTINENTAL",
                    12: "BANCO DEL ESTADO DE CHILE",
                    13: "SCOTIABANK PERU",
                    14: "SCOTIABANK SUD AMERICANO",
                    15: "BANK BOSTON",
                    16: "BANCO DE CREDITOS E INVER.",
                    17: "BANCO DO BRASIL S.A.",
                    18: "BANCO CENTRAL DE CHILE",
                    19: "B.TERRITORIAL",
                    20: "LLOYDS BANK",
                    23: "BANCO COMERCIO",
                    24: "CITIBANK ECUADOR",
                    25: "BANCO BADESCO",
                    26: "BANCO REGIONAL DEL NORTE-LIMA",
                    27: "CORPBANCA",
                    28: "BANCO BICE",
                    29: "BANCO DE A. EDWARDS",
                    30: "PACIFICO",
                    31: "HSBC BANK USA",
                    32: "BANK OF AMERICA",
                    33: "CITIBANK N.A.",
                    34: "BANCO REAL",
                    35: "BANCO SANTIAGO",
                    36: "BANESPA S.A.",
                    37: "BANCO SANTANDER - CHILE",
                    38: "BANCO EXTERIOR S.A.",
                    39: "BANK BOSTON N.A.",
                    40: "BANCO SUDAMERIS",
                    41: "JP MORGAN CHASE BANK",
                    42: "AMERICAN EXPRESS BANK LTD.",
                    43: "BANCO DE LA NACION ARGENTINA",
                    44: "SOLVENTA",
                    45: "BANK OF TOKIO-MITSUBISHI LTD",
                    46: "ABN AMRO BANK (CHILE)",
                    47: "ORION CORPORATION",
                    48: "BANCO DEL PAIS",
                    49: "BANCO SECURITY",
                    50: "BANCO CREDITO DEL PERU",
                    51: "BANCO FALABELLA",
                    52: "DEUTSCHE BANK (CHILE)",
                    53: "BANCO RIPLEY",
                    54: "HNS BANCO",
                    57: "BANCO PARIS",
                    59: "SOLIDARIO",
                    72: "BANCO RIO DE LA PLATA S.A.",
                    102: "BANCO SAN JOSE",
                    105: "BANCO MERCANTIL   DE VENEZUELA",
                    200: "BBVA ARGENTINA",
                    201: "BANCO DE RIO",
                    206: "CITIBANK URUGUAY",
                    237: "BANCO BRADESCO",
                    265: "BANCA NAZIONALE DEL LAVORO S.A.",
                    266: "BANQUE NATIONALE DE PARIS",
                    477: "CITIBANK",
                    504: "BBVA BANCO BHIF",
                    507: "BANCO DEL DESARROLLO",
                    511: "BANCO DE LA NACION ARGENTINA",
                    516: "CITIBANK ARGENTINA",
                    530: "BANCO AGRARIO DE COLOMBIA",
                    569: "RED MULTIBANCA COLPATRIA",
                    591: "BANCO DAVIVIENDA",
                    598: "BANCO DE BOGOTA",
                    612: "HELM BANK",
                    617: "BANCO MEGABANCO",
                    733: "FINANCIERA CONDELL S.A.",
                    734: "FINANCIERA CONOSUR",
                    745: "BANCO CITI DE BRASIL",
                    792: "BANCO SANTANDER RIO",
                    800: "BANCO SANTANDER",
                    822: "BANCO SUDAMERIS",
                    828: "BANCO TEQUENDAMA",
                    847: "BANCOLOMBIA",
                    999: "SIN BANCO",
                    1012: "BANCO RIO DE LA PLATA ARGENTINA",
                    1031: "BANCO PRODUBANCO",
                    1248: "BANCO BBVA COLOMBIA",
                    2266: "BCSC BANCO CAJA SOCIAL",
                    4414: "LLOYDS TSB BANK",
                    7697: "ABN AMBRO BANK",
                    7698: "BANCO DE OCCIDENTE",
                    7699: "BANCO POPULAR",
                    7700: "BANCO CITIBANK",
                    9966: "DELBANK"
                }
            },
            firstDayPayment: {
                type: "Date",
                format: "dd/MM/yyyy",
                description: "Date of first payment."
            },
            dayPaymentCode: {
                type: "Integer",
                values: {
                    1: "AL DIA",
                    2: "30",
                    3: "60",
                    4: "90",
                    5: "120",
                    6: ">120"
                }
            }
        }
    ],
    // Optional
    notificationContacts: [
        {
            contactType: {
                type: "String",
                maxSize: 1,
                description: "Type of contact.",
                values: {
                    D: "Destinatario (reciever)",
                    R: "Remitente (sender)"
                }
            },
            contactChannel: {
                type: "Integer",
                description: "Type of contact channel.",
                values: {
                    1: "Mobile.",
                    2: "Email."
                }
            },
            value: {
                type: "String",
                maxSize: 20,
                description: "Contacto type value (phone number or email)."
            },
            events: [
                {
                    code: {
                        type: "Integer",
                        description: "Type of event that Bluex's platform is goint to contact.",
                        values: {
                            "1": "En Retiro: El envío fue retirado de la bodega de origen.",
                            "2": "CD Blue: El envío está en el centro de distribución de Bluex.",
                            "3": "En Tránsito: El envío va en camino a la base Bluex más cercana al destino final.",
                            "4": "En Reparto: El envío están el camino al destino final.",
                            "5": "Entregado: El envío fue entregado al destinatario.",
                            "21": "Problema de Entrega: Ocurrió una excepción de entrega.",
                        }
                    }
                }
            ]
        }
    ],
    // Optional
    extras: [
        {
            name: {
                type: "String",
                description: "Extra key.",
            },
            value: {
                type: "String",
                description: "Extra value.",
            }
        }
    ]
};
export default emitRequest;