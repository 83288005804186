import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fontawesome/css/all.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './js/doc-detail.js';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const initProcess = () => {
  resetProcess();
  processStagesPoints('process-stage');
  processStagesPoints('process-title-action');
}
const processStagesPoints = (elementTag) => {
  let elements = document.getElementsByClassName(elementTag);
  let action = "";
  for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.addEventListener('click', (event) => {
          resetProcess();
          if (event.path[0].classList.contains(elementTag)) {
              event.path[0].classList.remove('process-circle');
              action = event.path[0].dataset.action;
              if (!event.path[0].classList.contains('process-title-action'))
                  event.path[0].classList.add('process-circle-active');
              else {
                  const circles = document.getElementsByClassName(`process-stage-${action}`);
                  for (let index = 0; index < circles.length; index++) {
                      const circle = circles[index];
                      circle.classList.add('process-circle-active');
                      circle.classList.remove('process-circle');
                  }
              }

          } else {
              event.path[1].classList.remove('process-circle');
              if (!event.path[0].classList.contains('process-title-action'))
                  event.path[1].classList.add('process-circle-active');
              action = event.path[1].dataset.action;
          }
          console.log(event);
          showProcessDetail(action);
      });
  }
  elements = document.getElementsByClassName('btn-close-details');
  for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.addEventListener('click', (event) => {
          resetProcess();
      })
  }
}
const showProcessDetail = (action) => {
  console.log(action);
  let elements = document.getElementsByClassName('process-detail-container');
  for (let index = 0; index < elements.length; index++) {
      elements[index].style.right = '0px';
  }
  hideAllDetails();
  elements = document.getElementsByClassName(`process-detail-${action}`);
  for (let index = 0; index < elements.length; index++) {
      elements[index].style.display = '';
  }

}
const hideAllDetails = () => {
  const details = [
      'bx-geo',
      'bx-pricing',
      'bx-emission',
      'bx-label',
      'bx-tracking-pull',
      'bx-tracking-push'
  ];

  for (let index = 0; index < details.length; index++) {
      let elements = document.getElementsByClassName(`process-detail-${details[index]}`);
      for (let j = 0; j < elements.length; j++) {
          elements[j].style.display = 'none';
      }
  }


}
const resetProcess = () => {
  let elements = document.getElementsByClassName('process-stage');
  for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.classList.remove('process-circle-active');
      element.classList.add('process-circle');
  }

  elements = document.getElementsByClassName('process-detail-container');
  for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      elements[index].style.right = '-101vw';
  }


}
reportWebVitals();
hideAllDetails();
initProcess();
